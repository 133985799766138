.languageItem {
    background-color: var(--secondary-bg-color);
    border-radius: var(--border-radius-large);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    margin-top: var(--margin-bottom);

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    }
}

.languagesBody {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.languagesTitle {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    text-align: left;
}

.language {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.625rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;

    &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
}

.flagIcon {
    width: 50px;
    height: auto;
    border-radius: 0.25rem;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.1);
    }
}

.languageTitle {
    font-size: 1.75rem;
    flex: 1;
    text-transform: capitalize;
}

.progressBar {
    width: 100%;
    max-width: 200px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 0.625rem;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.progress {
    height: 100%;
    border-radius: 0.625rem;
}

.native {
    width: 100%;
    background-color: #4caf50;
}

.fluent {
    width: 90%;
    background-color: #2196f3;
}

.advanced {
    width: 75%;
    background-color: #ff9800;
}

.basic {
    width: 40%;
    background-color: #f44336;
}

@media (max-width: 480px) {
    .languagesTitle {
        font-size: 1.5rem;
    }

    .languageTitle {
        font-size: 1.3rem;
    }

    .flagIcon {
        width: 40px;
    }
}

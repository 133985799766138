.resume {
    padding: 2rem;
    background-color: var(--secondary-bg-color);
    border-radius: var(--border-radius-large);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
}

.titleResume {
    font-size: 2rem;
    color: white;
    margin-bottom: 1rem;
    text-align: left;
    position: relative;
    animation: fadeInUp 0.8s ease-in-out;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.resumeText {
    font-size: 1.15rem;
    line-height: 1.6;
    color: var(--text-color);
    text-align: justify;
    transition: color 0.3s ease;

}

/* Responsividade */
@media (max-width: 768px) {
    .titleResume {
        font-size: 1.5rem;
    }

    .resumeText {
        font-size: 1.15rem;
    }
}

@media (max-width: 480px) {
    .titleResume {
        font-size: 1.5rem;
        text-align: center;
    }

    .resumeText {
        font-size: 1.15rem;
    }
}

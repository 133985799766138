.projects {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.projectsTitle {
    font-size: 3rem;
    color: white;
    margin-top: 1.5rem;
    margin-bottom: -1.25rem;
    margin-left: 2.5rem;
    animation: fadeInDown 1s ease-in-out;
}
.projectImageGuardianKey{
    width: 25%;  
    max-width: 423px;
    height: auto;
    object-fit: cover;
    border-radius: 0.625rem;
    margin-top: 20px;
    &:hover {
        transform: scale(1.05);
    }
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.buttonText {
    color: #ffff; // Exemplo de cor
    font-weight: bold; // Exemplo de estilo
    margin-top: 07px;
    margin-left: 10px; // Espaçamento à esquerda
}
.project {
    background-color: var(--secondary-bg-color);
    border-radius: var(--border-radius-large);
    padding: 3rem;
    color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 16px 32px rgba(0, 0, 0, 0.3);
    }
}

.projectTitle {
    font-size: 2rem;
    color: white;
    margin-bottom: 1rem;
    margin-left: 1.3rem;
}

.projectTitleGuardianKey {
    font-size: 2rem;
    margin-left: 3rem;
}

.projectTitleBudgetManagement {
    font-size: 2rem;
    color: white;
    margin-bottom: 1rem;
    margin-left: 0;
}

.projectTitleProdulink {
    font-size: 2rem;
    margin-left: 4.5rem;
}

.projectBody {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 1rem;
}

.projectImage {
    width: 100%;
    max-width: 423px;
    height: auto;
    object-fit: cover;
    border-radius: 0.625rem;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.05);
    }
}
.projectDescriptionGuardianKey{
    flex: 1;
    margin: 0;
    margin-left: 55px;
    margin-right: 30px;
    text-align: justify;
}
.projectDescription {
    flex: 1;
    margin: 0;
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
    transition: color 0.3s ease;
}

.buttonContainer {
    display: flex;
    gap: 1.3rem;
    justify-content: flex-start;
}

.buttonSeeProjectGuardianKey, .buttonSeeProject, .buttonSeeCode {
    margin-left: 4.5rem;
    background-color: white;
    color: black;
    border: none;
    border-radius: var(--border-radius-small);
    padding: 0.625rem 1.25rem;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
        background-color: #d1e7d1;
        transform: scale(1.05);
    }
}

@media (max-width: 480px) {
    .projectsTitle {
        font-size: 1.5rem;
    }
    .projectTitle {
        font-size: 2rem;
        margin-left: 30px;
    }
    .projectTitleGuardianKey{
        font-size: 2rem;
        margin-left: 30px;
    }
    .projectTitleBudgetManagement {
        font-size: 2rem;
        text-align: center;
    }
    .projectTitleProdulink {
        font-size: 2rem;
        margin-left: 60px;
    }
    .project {
        padding: 1rem;
    }
    .projectDescription {
        flex: 1;
        margin: 0;
        margin-left: 0;
        text-align: justify;
    }
    .projectDescriptionGuardianKey{
        margin-left: 05px;
        margin-right: 05px;
    }
}



@media (max-width: 768px) {
    .projectBody {
        flex-direction: column;
    }

    .projectImage {
        width: 80%;
        margin-bottom: 1rem;
        margin-left: 2rem;
    }
    .projectImageGuardianKey{
        width: 80%;  
        margin-bottom: 1rem;
        margin-left: 2rem;
        max-width: 423px;
        height: auto;
        object-fit: cover;
        border-radius: 0.625rem;
        margin-top: 80px;
    }
    .buttonContainer {
        flex-direction: column;
        align-items: stretch;
    }

    .buttonSeeProject, .buttonSeeCode {
        width: 100%;
        text-align: center;
        margin-left: 0;
    }
    .buttonSeeProjectGuardianKey{
        margin-left: 0;
    }
}

@media (min-width: 576px) { 
    .project {
        padding: 2rem;
    }
    .projectImageGuardianKey{
        width: 24%;  
    }
}

@media (min-width: 992px) { // Large (lg)
    .projectImage {
        width: 40%; 
    }
    .projectImageGuardianKey{
        width: 37%;  
    }
}

@media (min-width: 1200px) { // Extra large (xl)
    .projectImage {
        width: 30%; 
    }
    .projectImageGuardianKey{
        width: 28%;  
    }
}

@media (min-width: 1400px) { // Extra extra large (xxl)
    .projectImage {
        width: 25%; 
    }
    .projectImageGuardianKey{
        width: 23%;  
    }
}